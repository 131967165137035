<template>
  <v-card rounded="xl" class="mb-4">
    <v-list color="grey">
      <v-list-item :dense="$vuetify.breakpoint.xsOnly">
        <v-list-item-icon>
          <v-icon :size="!($vuetify.breakpoint.xsOnly || $vuetify.breakpoint.smOnly) ? 36 : null">
            {{ icons.mdiRecordCircleOutline }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title> Satış Analiz Raporu</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-card-actions class="grey">
      <v-row v-if="!$vuetify.breakpoint.mdAndDown" class="pt-3">
        <v-col cols="6" md="4">
          <v-select
            :disabled="loading"
            :items="tipElemanlar"
            v-model="tipDeger"
            label="Analiz Tipi"
            rounded
            filled
            dense
            background-color="nightDarkTon"
          ></v-select>
        </v-col>
        <v-col cols="6" md="4">
          <v-select
            :disabled="loading"
            :items="birimElemanlar"
            v-model="birimDeger"
            label="Analiz Birimi"
            rounded
            filled
            dense
            background-color="nightDarkTon"
          ></v-select>
        </v-col>
        <v-col cols="12" md="4">
          <v-select
            :disabled="loading"
            :items="tarihElemanlar"
            v-model="tarihDeger"
            label="Analiz Tarihi"
            rounded
            filled
            dense
            background-color="nightDarkTon"
          ></v-select>
        </v-col>
      </v-row>
      <v-row v-else dense>
        <v-col cols="12">
          <v-select
            :disabled="loading"
            :items="tarihElemanlar"
            v-model="tarihDeger"
            rounded
            filled
            dense
            hide-details
            background-color="nightDarkTon"
          ></v-select>
        </v-col>
        <v-col cols="6">
          <v-select
            :disabled="loading"
            :items="tipElemanlar"
            v-model="tipDeger"
            rounded
            filled
            dense
            hide-details
            background-color="nightDarkTon"
          ></v-select>
        </v-col>
        <v-col cols="6">
          <v-select
            :disabled="loading"
            :items="birimElemanlar"
            v-model="birimDeger"
            dense
            rounded
            hide-details
            filled
            background-color="nightDarkTon"
          ></v-select>
        </v-col>
      </v-row>
    </v-card-actions>
    <v-divider></v-divider>
    <v-card-text>
      <div class="satis_analiz--elemanlar">
        <div class="satis_analiz_eleman--kapsul" v-for="(eleman, index) in elemanlar" :key="index">
          <div class="satis_analiz_eleman--yuzey">
            <div class="satis_analiz_eleman--cerceve">
              <div class="satis_analiz_eleman--icerik">
                <div class="satis_analiz_eleman_kod satis_analiz_eleman--meta">
                  <v-tooltip right color="secondary">
                    <template v-slot:activator="{ on, attrs }">
                      <div
                        v-if="!$vuetify.breakpoint.xsOnly || tipDeger === 'product'"
                        class="text-caption ml-3"
                        v-bind="attrs"
                        v-on="on"
                        style="width: 110px; font-size: 11px !important"
                      >
                        {{ eleman.brand || eleman.id }}
                      </div>

                      <div
                        v-else
                        class="text-caption ml-3"
                        v-bind="attrs"
                        v-on="on"
                        style="width: 200px; font-size: 10px !important"
                      >
                        {{ eleman.brand || eleman.text }}
                      </div>
                    </template>
                    <span class="text-body-2">{{ eleman.text }}</span>
                  </v-tooltip>
                </div>
                <div
                  v-if="!$vuetify.breakpoint.xsOnly || tipDeger === 'product'"
                  class="satis_analiz_eleman_gorsel satis_analiz_eleman--meta"
                >
                  <v-tooltip left color="white">
                    <template v-slot:activator="{ on, attrs }">
                      <v-avatar color="white" v-bind="attrs" v-on="on">
                        <img v-if="tipDeger === 'product'" :src="eleman.src" />
                        <div>Ş</div>
                      </v-avatar>
                    </template>
                    <img :src="eleman.src" width="300" />
                  </v-tooltip>
                </div>
              </div>
              <div class="satis_analiz_eleman_yuzde" v-show="!$vuetify.breakpoint.mdAndDown">
                <span class="text-caption">{{ eleman.text }}</span>
              </div>
              <div class="satis_analiz_eleman_deger satis_analiz_eleman--meta">
                <span
                  class="white--text font-weight-black text-subtitle-2 ml-3 dataCount"
                  :data-sss="eleman.value"
                  style="text-wrap: nowrap"
                  >{{ eleman.value }} </span
                ><span class="white--text font-weight-black text-subtitle-2 ml-1">
                  {{ birimDeger === 'price' ? 'TL' : 'Ton' }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { getCurrentInstance, onMounted, computed, ref, watch } from '@vue/composition-api'
import store from '@/store'

// Plugin
import gsap from 'gsap'
import moment from 'moment'

// icon
import { mdiRecordCircleOutline } from '@mdi/js'
import { currency, postData } from '@utils'

export default {
  setup() {
    const tipDeger = ref('product')
    const tipElemanlar = ref([
      { text: 'Ürün', value: 'product' },
      { text: 'Müşteri', value: 'customer' },
    ])

    const birimDeger = ref('price')
    const birimElemanlar = ref([
      { text: 'Tutar', value: 'price' },
      { text: 'Tonaj', value: 'tonnage' },
    ])
    const tarihDeger = ref([])
    const tarihElemanlar = ref([])

    const ilkDeger = ref(0)
    const elemanlar = ref([])

    const animasyon = () => {
      gsap.set('.satis_analiz--elemanlar', { autoAlpha: 1 })
      gsap.set('.satis_analiz_eleman_deger', { autoAlpha: 0 })
      gsap.fromTo(
        '.satis_analiz_eleman--cerceve',
        {
          y: 300,

          autoAlpha: 0,
        },
        {
          y: 0,

          autoAlpha: 1,
          stagger: 0.1,
          duration: 0.7,
          ease: 'back.out(1)',
          onComplete: () => {
            gsap.to('.satis_analiz_eleman_deger', { autoAlpha: 1 })
            const items = document.querySelectorAll('.dataCount')
            gsap.from(items, {
              textContent: 0,
              duration: 3,
              ease: 'power1.in',
              snap: { textContent: 1 },
              stagger: {
                each: 0.1,
                onUpdate: function () {
                  this.targets()[0].innerHTML = numberWithCommas(Math.ceil(this.targets()[0].textContent))
                },
              },
            })

            document.querySelectorAll('.satis_analiz_eleman_yuzde').forEach((section, index) => {
              let width
              if (index === 0) {
                width = 'calc(100% + 40px)'
                ilkDeger.value = elemanlar.value[0].value
              } else {
                width = 'calc(' + (elemanlar.value[index].value * 100) / ilkDeger.value + '% + 0px)'
              }

              gsap.to(section, {
                width,
                paddingLeft: 40,
                duration: 3,
                ease: 'power1.in',
                onComplete: function () {
                  loading.value = false
                },
              })
            })
          },
        },
      )
    }

    const getMonthDateRange = (year, month) => {
      var startDate = moment([year, month])
      var endDate = moment(startDate).endOf('month')

      tarihElemanlar.value.push({
        text: moment(startDate).format('MMMM YYYY'),
        value: [`"${moment(startDate).format('DD.MM.YYYY')}"`, `"${moment(endDate).format('DD.MM.YYYY')}"`],
      })
    }

    const loading = ref(true)

    const satisRaporTekrar = async () => {
      const devam = await satisRapor()
      if (devam) {
        gsap.set('.satis_analiz_eleman_yuzde', { width: 0 })
        const items = document.querySelectorAll('.dataCount')
        items.forEach(item => {
          item.innerHTML = item.dataset.sss
        })

        animasyon()
      }
    }
    watch([tipDeger, birimDeger, tarihDeger], val => {
      if (!dur.value) {
        gsap.fromTo(
          '.satis_analiz_eleman--cerceve',
          {
            x: 0,

            autoAlpha: 1,
          },
          {
            x: -20,

            autoAlpha: 0,
            stagger: 0.1,
            duration: 0.5,
            ease: 'back.out(2)',
            onComplete: function () {
              gsap.set('.satis_analiz_eleman--cerceve', { x: 0 })
              satisRaporTekrar()
            },
          },
        )
      }
    })

    const satisRapor = async () => {
      loading.value = true
      let result = true
      try {
        await postData({
          method: 'dashboardSalesReport',
          daterange: `[${tarihDeger.value}]`,
          type: tipDeger.value,
          unit: birimDeger.value,
        }).then(response => {
          if (response.error === 0) {
            // elemanlar.value = response.data.slice(0, 8)
            elemanlar.value = response.data
          } else {
            throw new Error(response.msg)
          }
        })
      } catch (e) {
        result = false
      } finally {
        return result
      }
    }

    const dur = ref(true)
    onMounted(async () => {
      gsap.set('.satis_analiz--elemanlar', { autoAlpha: 0 })
      var d = new Date()
      d.setMonth(d.getMonth() - 1)
      for (var i = 0; i <= 11; i++) {
        getMonthDateRange(d.getFullYear(), d.getMonth())
        d.setMonth(d.getMonth() - 1)
      }

      tarihDeger.value = tarihElemanlar.value[0].value

      const devam = await satisRapor()
      dur.value = false
      if (devam) {
        animasyon()
      }
    })

    const numberWithCommas = x => {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    }

    return {
      loading,
      elemanlar,
      tipDeger,
      tipElemanlar,
      birimDeger,
      birimElemanlar,
      tarihDeger,
      tarihElemanlar,
      icons: {
        mdiRecordCircleOutline,
      },
    }
  },
}
</script>
<style lang="scss">
.satis_analiz--kapsul {
  width: 100%;
  height: 100%;
}
.satis_analiz_eleman--kapsul {
  width: 100%;
}
.satis_analiz_eleman--yuzey {
  display: flex;
  align-items: center;
  padding: 7px 0;
}
.satis_analiz--yuzey {
  height: 100%;
}
.satis_analiz--elemanlar {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.satis_analiz_eleman--cerceve {
  display: flex;
  align-items: center;
  width: 100%;
}
.satis_analiz_eleman--icerik {
  display: flex;
  align-items: center;

  background: #201c2d;
  border-radius: 100px;
  position: relative;
  z-index: 10;
}
.satis_analiz_eleman_deger {
  text-wrap: nowrap;
}
.satis_analiz_eleman_yuzde {
  position: relative;
  z-index: 1;
  background: #0083e8;
  height: 58px;
  width: 0px;
  margin-left: -30px;
  border-top-right-radius: 100px;
  border-bottom-right-radius: 100px;
  display: flex;
  align-items: center;
  padding-left: 0px;
  overflow: hidden;
  text-wrap: nowrap;
}
.satis_analiz_eleman--meta {
  padding: 5px;
}
</style>
